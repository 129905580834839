const AddToCart = () => import('./add-to-cart');
const ProductInfos = () => import('./product-infos');
const ProductImageViewer = () => import('./product-image-viewer');
const ProductTitle = () => import('./product-title');
const ProductMissing = () => import('./product-missing');
const ProductShippingOptions = () => import('./product-shipping-options');
const ProductBenefits = () => import('./product-benefits');
const ProductKitDiscount = () => import('./product-kit-discount');
const ProductAdvertence = () => import('./product-advertence');
const ProductCompare = () => import('./product-compare');
const ProductDiscontinued = () => import('./product-discontinued');
const ProductGenericsTag = () => import('./product-generics-tag');
const ProductCoupon = () => import('./product-coupon');

export {AddToCart as _AddToCart};
export {ProductInfos as _ProductInfos};
export {ProductImageViewer as _ProductImageViewer};
export {ProductTitle as _ProductTitle};
export {ProductMissing as _ProductMissing};
export {ProductShippingOptions as _ProductShippingOptions};
export {ProductBenefits as _ProductBenefits};
export {ProductKitDiscount as _ProductKitDiscount};
export {ProductAdvertence as _ProductAdvertence};
export {ProductCompare as _ProductCompare};
export {ProductDiscontinued as _ProductDiscontinued};
export {ProductGenericsTag as _ProductGenericsTag};
export {ProductCoupon as _ProductCoupon};
